<template>
  <v-list nav>
    <v-list-item-group>
      <template>
        <v-list-item :to="{ name: 'Home' }">
          <v-list-item-action>
            <v-icon>keyboard_return</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("pages.home") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
      </template>

      <v-list-group
        group="/cobranca/"
        active-class="v-list-item--active"
        prepend-icon="attach_money"
      >
        <template v-slot:activator>
          <v-list-item-title>Cobrança</v-list-item-title>
        </template>

        <v-list-item dense :to="{ name: 'RelatorioListaBloqueio' }">
          <v-list-item-content>
            <v-list-item-title class="font-weight-regular">
              Lista de Bloqueio
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon size="20">description</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item dense :to="{ name: 'RelatorioListaAtrasados' }">
          <v-list-item-content>
            <v-list-item-title class="font-weight-regular">
              Lista de Atrasados
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon size="20">description</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item dense :to="{ name: 'RelatorioListaVencidosDia' }">
          <v-list-item-content>
            <v-list-item-title class="font-weight-regular">
              Lista de Vencidos do Dia
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon size="20">description</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-group>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MenuRelatorios",
  computed: {
    // ...mapGetters("Componentes", ["getAccess"]),
    // vueVariaveis() {
    //   return this.getAccess("vueVariaveis", "menu");
    // },
  },
};
</script>

<style></style>
